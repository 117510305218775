import { Sidebar } from "../../../../components/sidebar/Sidebar";
import styles from "./NewCustomer.module.css";

import React, { useEffect, useId, useState } from "react";
import { Formik, Field, Form, ErrorMessage, useField } from "formik";
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../../../components/label/Labels";
import { FormTextArea, TextInput } from "../../../../components/input/inputs";
import * as Yup from "yup";
import { Submit, BackButton } from "../../../../components/button/buttons";
import FileAttachment from "../../../../components/fileAttachment/fileAttachment.container";
import { DetailContainer } from "../../../../components/comment/comment";
import Asterisk from "../../../../components/asterisk/Asterisk";
import IMAGES from "../../../../assets/images/images";
import * as DOMPurify from "dompurify";
import Tooltip from "../../../../components/toolTip/toolTip";
import DropDown from "../../../../components/dropDown/DropDown";
import { SearchField } from "../../../../components/search/search";
import CustomerViewer from "../list/CustomerViewer";
import { useNavigate, Link } from "react-router-dom";
import { Incident_Service_Type_Values } from "../../../../utils/constants/incidentConstants";
import { formatSecondsToDate } from "../../../../utils/dateTime";
import { FUNCTIONAL_ROLES_CONSTANTS } from "../../../../utils/constants/userConstants";
import { capitalizeWords } from "../../../../utils/utils";
import { UserManagementTab } from "../../../../utils/constants/userManagementConstant";

const ButtonWrapper = ({ children }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};
export const Wrapper = ({ children }) => {
  return <div className={styles.wrapperContainer}>{children}</div>;
};

const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldWrapper}>{children}</div>;
};

const RadioField = ({ name, value, label }) => {
  const id = useId();
  return (
    <div className={styles.radioField}>
      <Field
        id={id}
        className={styles.radioInput}
        type="radio"
        name={name}
        value={value}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );
};
const CustomerNotFound = () => {
  return (
    <div className={styles.customerNotFound}>
      <div className={styles.customerNottxt}> No Customer Found</div>
      <div className={styles.customerNotFoundtxt}>
        Would you like to onboard a new customer?
      </div>
    </div>
  );
};
const CustomerList = (props) => {
  const navigate = useNavigate();

  function keyValueExistsInArray(arr, keyToCheck, valueToCheck) {
    for (const element of arr) {
      if (element[keyToCheck] === valueToCheck) {
        return true;
      }
      if (Array.isArray(element.accountManagers)) {
        if (keyValueExistsInArray(element.accountManagers, keyToCheck, valueToCheck)) {
          return true;
        }
      }
    }
    return false;
  }

  const gotoDetail = (item) => {
    let super_admin = props?.myRights?.functionalRoles[0]===FUNCTIONAL_ROLES_CONSTANTS["super_admin_internal"]?.value
    const result = keyValueExistsInArray(item.serviceSpecificDetails, "email", props.myEmail)
    if (result || super_admin) {
      navigate(`/user-management?customerId=${encodeURIComponent(item.uniqueCustomerRefId)}`)
    } else {
      navigate(`/AccessApprovalDetail/${item.id}`)
    }
  };

  return (
    <div>
      <div className={styles.containerheader}>Existing Onboarded Customers</div>
      {/* <div className={styles.headnote}>
        <img className={styles.infocircle} src={IMAGES.infoCircle} />
        Select the customer for whom you would like to add a service.
      </div> */}
      {props.list?.map((item, key) => (
        <div className={styles.containers} key={key}>
          <div className={styles.headsearch}>
            <div className={styles.orgnamehead}>{item?.displayName}</div>
            <div className={styles.subhead}>
              <img onClick={() => gotoDetail(item,props?.myRights)} src={IMAGES.chevronredright} />
              {/* <Link
                to={`/AccessApprovalDetail
               `}
              >
                <img src={IMAGES.chevronredright} alt="" />
              </Link> */}
            </div>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.parentDiv}>
              <div className={styles.headData}>
                <div className={styles.headSub}>Service Type</div>
                <div className={styles.sub}>
                  {item.serviceSpecificDetails
                    ?.map((detail) => detail.serviceType.label)
                    .join(" & ")}
                </div>
              </div>
            </div>
            <div className={styles.parentDiv}>
              <div className={styles.headData}>
                <div className={styles.headSub}>Organisation Legal Name</div>
                <div className={styles.sub}>{item?.displayName}</div>
              </div>
            </div>
            <div className={styles.parentDiv}>
              <div className={styles.headData}>
                <div className={styles.headSub}>Onboarded On</div>
                <div className={styles.sub}>
                  {formatSecondsToDate(item?.createdAt)}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const Search = ({ label, placeholder, onChange, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? { ...styles.inputError, width: "250px" }
    : { width: "250px" };

  return (
    <FieldWrapper>
      <div>
        <Label text={label} style={styles.labelcompLevel} /> <Asterisk />
      </div>
      <SearchField
        {...field}
        {...props}
        placeholder={placeholder}
        style={styles.additionalStyle}
        onChange={(e) => {
          helpers.setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
      {/* <img className={styles.serchicon} src={IMAGES.searchicon} alt="" /> */}
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const CheckBox = ({ name, value, label, onChange, checked }) => {
  const id = useId();
  return (
    <div className={styles.radioField}>
      <Field
        id={id}
        className={styles.radioInput}
        type="checkbox"
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );
};

const ImageUpload = ({
  setFieldValue,
  value,
  label = "Supporting Attachments",
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper>
      <div className={styles.labelcomment}>
        <Label text={label} optional={false} style={styles.labelcompLevel} />
      </div>
      <FileAttachment
        name={props.name}
        value={value}
        setFieldValue={setFieldValue}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const TextField = ({ label, placeholder, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError ? styles.inputError : null;
  return (
    <FieldWrapper>
      <div>
        <Label text={label} style={styles.labelcompLevel} />
        <Asterisk />
        {props.toltip ? (
          <span className={styles.toltip}>
            <Tooltip text={"TADIG Code"} />
          </span>
        ) : (
          <></>
        )}
      </div>
      <TextInput
        {...field}
        {...props}
        placeholder={placeholder}
        style={inputStyle}
      //   onChange={props.onChange}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const SelectField = ({ title, options, ...props }) => {
  let _options = options.map((item) => ({
    value: item.value,
    label: item.label,
  }));
  const [field, meta, helpers] = useField(props);

  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper>
      <div>
        <Label
          text={title}
          style={`${styles.labelcompLevel} ${props.additionalStyle}`}
        />
        <span className={styles.circlered}>
          <span className={styles.tooltip}>
            <Asterisk />
          </span>
        </span>
      </div>
      <DropDown
        options={_options}
        customStyles={props.customStyles}
        onChange={({ label, value }) => {
          props.onChange(value);
          helpers.setValue(value);
        }}
        value={
          _options && field.value
            ? _options.find((option) => option.value === field.value)
            : ""
        }
        hasError={hasError}
        isDisabled={props.isDisabled}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const TextArea = ({ label, optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError}`
    : styles.input;
  return (
    <FieldWrapper>
      <div className={styles.labelcomment}>
        <Label text={label} optional={optional} style={styles.labelcompLevel} />
      </div>
      <FormTextArea {...field} {...props} style={styles.additionalStyle} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const NewCustomer = (props) => {
  const [show, setShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [continueClicked, setContinueClicked] = useState(false);
  let failuerTypeDropdownDropObj = {
    width: "300px",
  };

  const YesContinue = () => {
    setContinueClicked(true);
  };

  const filteredList = props?.list?.filter((item) => {
    return item?.displayName?.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const accountManager = props?.accountManagerList.map((item) => ({
    value: item.email,
    label: item.displayName,
  }));

  useEffect(() => {
    props.getAccountManagerList();
  }, []);

  useEffect(() => {
    setContinueClicked(false)
  }, [searchQuery]);

  const close = () => {
    setShow(false);
    setSearchQuery("");
    setContinueClicked(false);
  };

  const validationSchema = Yup.object().shape({
    organisationLegalName: Yup.string().required(" Organisation Legal Name is Required"),
    serviceType: Yup.string().required("Kindly choose service type."),
    tadigCode: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IPX_Service,
      then: () => Yup.string().required("TADIG Code is required"),
    }),
    primaryContactName: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IPX_Service,
      then: () => Yup.string().required("Primary Contact is required"),
    }),
    primaryEmailAddress: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IPX_Service,
      then: () => Yup.string().required("Primary Email is required"),
    }),
    accountManager: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IPX_Service,
      then: () => Yup.string().required("Account Manager is required"),
    }),

    primaryContactNameivc: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IVC_Service,
      then: () => Yup.string().required("Primary Contact is required"),
    }),
    primaryEmailAddressivc: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IVC_Service,
      then: () => Yup.string().required("Primary Email is required"),
    }),
    accountManagerivc: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IVC_Service,
      then: () => Yup.string().required("Account Manager is required"),
    }),
    accountAbbr: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IVC_Service,
      then: () => Yup.string().required("accountAbbr is required"),
    }),
    accountNumber: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IVC_Service,
      then: () => Yup.string().required("Account Number is required"),
    }),
    additionalInformation: Yup.string().max(1000, "Text must be at most 1000 characters"),
    files: Yup.array().max(3, "You can upload up to 3 files"),
  });


  const getAccountManagerName = (email) => {
    let accountManagerName = accountManager.filter(
      (item) => item.value == email
    );
    return accountManagerName[0].label;
  };

  const AccountManagerNote =({myRights})=>{
    const handleClick = ()=>{
      props.setActivePrimaryTab(UserManagementTab.VF_GROUP_USER)
      props.redirectTab(UserManagementTab.VF_GROUP_USER)
      close();
    }
    return(
      <>
         {myRights?.functionalRoles[0]===FUNCTIONAL_ROLES_CONSTANTS["super_admin_internal"]?.value &&(
            <span className={styles.amnote}>If you don't find the Account Manager you want to assign, please onboard them first on the platform from <span className={styles.navigatevf} onClick={handleClick}>here.</span></span>
         )}
         {myRights?.functionalRoles[0]===FUNCTIONAL_ROLES_CONSTANTS["account_manager_internal"]?.value &&(
           <span className={styles.amnote}>If you don't find the Account Manager you want to assign, please contact the Super Admin.</span>
         )}
      </>
    )
  }

  return (
    <>
      <button onClick={() => setShow(true)} className={styles.continue}>
        Add New Customer
      </button>
      {show && (
        <Sidebar
          isOpen={show}
          headerTxt="Add New Customer"
          onClose={close}
          sideNavStyle={styles.sideNavStyle}
        >
          <div>
            <Formik
              validationSchema={validationSchema}
              initialValues={{
                serviceType: "",
                organisationLegalName: "",
                tadigCode: "",
                primaryContactName: "",
                primaryEmailAddress: "",
                accountManager: "",
                accountManagerEmail: "",
                primaryContactNameivc: "",
                primaryEmailAddressivc: "",
                accountManagerivc: "",
                accountManagerEmailivc: "",
                accountAbbr: "",
                accountNumber: "",
                additionalInformation: "",
                files: [],
              }}
              onSubmit={async (values, { resetForm }) => {
                setIsSubmit(true);
                let obj = {
                  displayName: values.organisationLegalName,
                  uniqueCustomerRefId: null,
                  requestType: "new_onboarding",
                  requestDetails: [],
                  additionalInfoText: values.additionalInformation,
                  additionalInfoAttachments: values.files,
                };

                if (values.serviceType == Incident_Service_Type_Values.IVC_Service) {
                  obj.requestDetails.push({
                    cpName: null,
                    serviceType: values.serviceType,
                    accountAbbr: values.accountAbbr,
                    accountNumber: values.accountNumber,
                    customerPrimaryContact: [
                      {
                        name: values.primaryContactNameivc,
                        email: values.primaryEmailAddressivc,
                      },
                    ],
                    customerAccountManagers: [
                      {
                        name: getAccountManagerName(values.accountManagerivc),
                        email: values.accountManagerEmailivc,
                      },
                    ],
                  });
                } else if (values.serviceType == Incident_Service_Type_Values.IPX_Service) {
                  obj.requestDetails.push({
                    cpName: values.tadigCode,
                    serviceType: values.serviceType,
                    customerPrimaryContact: [
                      {
                        name: values.primaryContactName,
                        email: values.primaryEmailAddress,
                      },
                    ],
                    customerAccountManagers: [
                      {
                        name: getAccountManagerName(values.accountManager),
                        email: values.accountManagerEmail,
                      },
                    ],
                  });
                }
                props
                  .submitRequest(obj)
                  .then((result) => {
                    if (result.status) {
                      props.openAlertPopup({
                        message: `Customer onboarding request with ID: ${result?.res?.data?.requestId} has been sent to the Commercial Manager!`,
                        image: IMAGES.success,
                      });
                      setIsSubmit(false);
                      resetForm();
                      close();
                      // props.getServiceList();
                    } else {
                      let error_msg =
                        result?.error?.message ?? result.error;

                      props.openAlertPopup({
                        message: error_msg,
                        image: IMAGES.error,
                        // isFormOpen: true,
                      });
                      setIsSubmit(false);
                      close();
                    }
                  })
                  .catch((error) => {
                    props.openAlertPopup({
                      message: "Something went wrong.",
                      image: IMAGES.error,
                      //  isFormOpen: true,
                    });
                    setIsSubmit(false);
                    close();
                  });
              }}
            >
              {({
                isValid,
                dirty,
                values,
                setFieldValue,
                resetForm,
                handleChange,
              }) => (
                <>
                  <Form  className={`${styles.form} ${styles.scroll}`} id="Add_Customer"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                      }
                    }}
                  >
                    <Search
                      label="Organisation Legal Name"
                      placeholder="Search customer by typing their organisation legal name"
                      name="organisationLegalName"
                      customStyles={failuerTypeDropdownDropObj}
                      onChange={(value)=>{
                        setFieldValue("organisationLegalName",capitalizeWords(value));
                        setSearchQuery(value)
                      }}
                    />
                    {!searchQuery && (


                      <div className={styles.initalinfo}>
                        <div className={styles.initalinfohead}>
                          How to onboard a new customer?
                        </div>
                        <div className={styles.containerinfo}>
                          <div className={styles.dflexs}>
                            <div className={styles.redflex}>
                              <div className={styles.redin}></div>
                              <div className={styles.blackin}></div>
                            </div>
                            <div className={styles.black}></div>
                            <div className={styles.black}></div>
                            <div className={styles.black}></div>
                            <div className={styles.black}></div>
                            <div className={styles.black}></div>
                            <div className={styles.black}></div>
                            <div className={styles.redflex}>
                              <div className={styles.redin}></div>
                              <div className={styles.blackin}></div>
                            </div>
                          </div>
                          <div className={styles.dflex}>
                            <div className={styles.initalinfotext}>
                              <span className={styles.userinfosearch}> <img className={styles.userinfosearchimg} src={IMAGES.userinfosearch} /> </span>
                              1. Search For Existing Customer
                              <li className={styles.info}>
                                Before onboarding a new customer, search by their
                                organisation’s legal name.
                              </li>
                            </div>
                            <div className={styles.initalinfotext}>
                              <span className={styles.userinfosearch}> <img className={styles.userinfosearchimg} src={IMAGES.userinfosearch} /> </span>

                              2. Create Account
                              {/* <li className={styles.info}>
                            For existing customer, request access to update
                            their information.
                          </li> */}
                              <li className={styles.info}>
                                If customer does not exist, create a new customer
                                account.
                              </li>
                            </div>
                          </div>
                        </div>
                      </div>
                      // <div>
                      //   <img
                      //     className={styles.onboardinfoimg}
                      //     src={IMAGES.onboardinfo}
                      //     alt=""
                      //   />
                      // </div>
                    )}
                    {/* {console.log("my",props.myRights)} */}
                    {searchQuery && (
                      <>
                        {!continueClicked && filteredList?.length > 0 ? (
                          <div className={styles.containerlist}>
                            <CustomerList list={filteredList} myEmail={props.myRights.email} myRights={props.myRights} />
                          </div>
                        ) : (
                          !continueClicked && <CustomerNotFound />
                        )}
                        {continueClicked && (
                          <>
                            <div className={styles.chackboxtxt}>
                              Choose one or more service type{" "}
                              <Tooltip
                                text={"Choose one or more service type"}
                              />
                              <div></div>
                            </div>
                            <div className={styles.chackboxmain}>
                              <div>
                                <RadioField name="serviceType" value={Incident_Service_Type_Values.IPX_Service} label={"IPX Services"} />
                              </div>
                              <div>
                                <RadioField name="serviceType" value={Incident_Service_Type_Values.IVC_Service} label={"International Voice Services"} />
                              </div>
                            </div>
                            <ErrorMessage name={"serviceType"}>
                              {(msg) => <ErrorLabel text={msg} />}
                            </ErrorMessage>
                            {values.serviceType == Incident_Service_Type_Values.IPX_Service && (
                              <>
                                <TextField
                                  label="TADIG Code"
                                  name="tadigCode"
                                  type="text"
                                  toltip="true"
                                  placeholder="Enter ID"
                                />

                                <div className={styles.select}>
                                  <div
                                    className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                                  >
                                    <TextField
                                      label="Customer Primary Contact Name"
                                      name="primaryContactName"
                                      type="text"
                                      placeholder="Enter name"
                                      onChange={(e)=>{
                                        setFieldValue("primaryContactName",capitalizeWords(e.target.value));
                                        }}
                                    />
                                  </div>
                                  <div className={styles.selectwidth}>
                                    <TextField
                                      label="Customer Primary Email Address"
                                      name="primaryEmailAddress"
                                      type="text"
                                      placeholder="Enter email address"
                                    />
                                  </div>
                                </div>
                                <div className={styles.select}>
                                  <div
                                    className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                                  >
                                    <SelectField
                                      title={"Account Manager"}
                                      name="accountManager"
                                      onChange={(value) => {
                                        setFieldValue(
                                          "accountManagerEmail",
                                          value
                                        );
                                        //handleChange();
                                      }}
                                      options={accountManager}
                                      customStyles={styles.obj}
                                      placeholder="Enter name"
                                      additionalStyle={styles.additionalStyle}
                                    />
                                  </div>
                                  <div className={styles.selectwidth}>

                                    <TextField
                                      label="Account Manager Email Address"
                                      name="accountManagerEmail"
                                      type="text"
                                      value={values.accountManagerEmail}
                                      placeholder=""
                                      readOnly
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                                <AccountManagerNote myRights={props?.myRights}/>
                              </>
                            )}
                            {values.serviceType == Incident_Service_Type_Values.IVC_Service && (
                              <>
                                <div className={styles.select}>
                                  <div
                                    className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                                  >
                                    <TextField
                                      label="Account Abbreviation"
                                      name="accountAbbr"
                                      type="text"
                                      placeholder="Input text"
                                    />
                                  </div>
                                  <div className={styles.selectwidth}>
                                    <TextField
                                      label="Account Number"
                                      name="accountNumber"
                                      type="text"
                                      placeholder="Input text"
                                    />
                                  </div>
                                </div>
                                <div className={styles.select}>
                                  <div
                                    className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                                  >
                                    <TextField
                                      label="Customer Primary Contact Name"
                                      name="primaryContactNameivc"
                                      type="text"
                                      placeholder="Input text"
                                      onChange={(e)=>{
                                        setFieldValue("primaryContactNameivc",capitalizeWords(e.target.value));
                                        }}
                                      
                                    />
                                  </div>
                                  <div className={styles.selectwidth}>
                                    <TextField
                                      label="Customer Primary Email Address"
                                      name="primaryEmailAddressivc"
                                      type="text"
                                      placeholder="Input text"
                                    />
                                  </div>
                                </div>
                                <div className={styles.select}>
                                  <div
                                    className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                                  >
                                    <SelectField
                                      title={"Account Manager"}
                                      name="accountManagerivc"
                                      options={accountManager}
                                      customStyles={styles.obj}
                                      onChange={(value) => {
                                        setFieldValue(
                                          "accountManagerEmailivc",
                                          value
                                        );
                                        //handleChange();
                                      }}
                                      additionalStyle={styles.additionalStyle}
                                    />
                                  </div>
                                  <div className={styles.selectwidth}>

                                    <TextField
                                      label="Account Manager Email Address"
                                      name="accountManagerEmailivc"
                                      value={values.accountManagerEmailivc}
                                      type="text"
                                      placeholder=""
                                      readOnly
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                                <AccountManagerNote myRights={props?.myRights}/>
                              </>
                            )}
                            {(values.serviceType) && (
                              <>
                                <TextArea
                                  label={"Additional Information"}
                                  id="additionalInformation"
                                  name="additionalInformation"
                                  placeholder="Enter the information"
                                />
                                <ImageUpload
                                  name="files"
                                  value={values.files}
                                  setFieldValue={setFieldValue}
                                  optional={false}
                                />
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Form>
                  <div className={styles.footer}>
                    <div>
                      <BackButton
                        className={styles.backButton}
                        style={styles.back}
                        title={"Back"}
                        closePopup={close}
                      />
                    </div>
                    <div>
                      {continueClicked ? (
                        <>
                          <Submit
                            id="saveButton"
                            className={styles.saveButton}
                            style={styles.submit}
                            title={"Submit"}
                            form="Add_Customer"
                            isSubmitting={isSubmit}
                            disabled={!dirty || !isValid}
                          />
                        </>
                      ) : (
                        <button
                          className={styles.continuebutton}
                          onClick={YesContinue}
                          disabled={searchQuery?.length === 0}
                        >
                          Create Account
                        </button>
                      )}
                    </div>
                    {/* )}  */}
                  </div>
                </>
              )}
            </Formik>
          </div>
        </Sidebar>
      )}
    </>
  );
};

export default NewCustomer;
