import Select from 'react-select'

const dropdownStyle = {
    control: (provided, state)=> {
        const {selectProps: {hasError}, isDisabled,isFocused} = state
       
        return (
            {...provided, pointerEvents: 'initial', backgroundColor: isDisabled ? "#CCCCCC" : "#fff",cursor: isDisabled ? 'not-allowed' : 'pointer', borderRadius: 5, height: 48,borderWidth: 1, borderColor: isFocused ? "#555" : (hasError ? "#CD3500" : "#979797")
                , boxShadow: isFocused ? '0 0 0 1px #555' : 'none', // Ensure the focus border color is applied
                '&:hover': {
                    borderColor: isFocused ? "#555" : (hasError ? "#CD3500" : "#979797")
                }
            }
        );
    },
    singleValue: (provided, state)=> ({...provided,  backgroundColor: "transparent", color: "#0D0D0D", fontFamily: "VodafoneRg-Regular", fontSize:20}),
    placeholder: (provided, state)=> ({...provided, backgroundColor: "transparent", color: "#666666", fontFamily: "VodafoneRg-Regular", fontSize:20}),
    indicatorSeparator: (provided, state)=> ({...provided, backgroundColor: "transparent", width: 0}),
    dropdownIndicator: (provided, state)=> ({...provided, color: "#333333"}),
    
    //container: (provided, state)=> ({...provided, width: 500,}),
    container: (provided, state)=> {
        const {selectProps: {customStyles}} = state
        return (
            {...provided, ...customStyles}
        );
    },
}
let obj={
    width:500 
}
const DropDown = ({options,placeholder, onChange,value, hasError,isDisabled=false,customStyles=obj })=>{
    return (
        <Select 
            placeholder={placeholder}
            options={options}
            onChange={onChange}
            styles={dropdownStyle}
            hasError={hasError}
            value={value}
            customStyles={customStyles}
            isDisabled={isDisabled}
           />
    );
}

export default DropDown;